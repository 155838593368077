.search-selection-details {
    
}

.search-selection-details table {
    width: 100%;
    min-width: 280px;
}

.search-selection-details td {
    width: 50%;
}