.template-search {
    padding: 20px;
}

.template-search .search {
    margin-top: 8px;
}

.template-search .search__input-text {
    font-size: 14px;
}

.template-search__title {
    font-size: 15px;
    margin: 0;
    font-weight: bold;
}
