div.add-to-cart-button-with-quantity {
    position: relative;
    height: 36px;
    width: 120px;
    display: flex;
    margin: 0 1em;
}

div.add-to-cart-button-with-quantity:after {
    content: '';
    border: 2px solid #ff671b;
    background: #fff;
    position: absolute;
    height: 36px;
    width: 100%;
    top: 0;
    left: 0;
    transform: skew(-10deg);
    z-index: 0;
    box-shadow: inset 0px 3px 4px 0 rgba(0, 0, 0, 0.1);
}

div.add-to-cart-button-with-quantity input {
    border: 0;
    height: 100%;
    width: 80px;
    margin: 1px -2px 0 0;
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;
    z-index: 1;
    background: transparent;
    font-size: 16px;
}

div.add-to-cart-button-with-quantity input::-webkit-inner-spin-button,
div.add-to-cart-button-with-quantity input::-webkit-outer-spin-button {
    opacity: 1;
}

div.add-to-cart-button-with-quantity input:focus,
div.add-to-cart-button-with-quantity input:active
{
    border-color: transparent !important;
    box-shadow: none !important;
    outline: none;
}

div.add-to-cart-button-with-quantity button.add-to-cart-button-submit {
    position: relative;
    border: 0;
    height: 100%;
    width: 100%;
    background-color: #ff671b;
    z-index: 1;
    transform: skew(-10deg);
    outline: none;
}

div.add-to-cart-button-with-quantity button.add-to-cart-button-submit.with-icon {
    position: relative;
    display: block;
    background-color: #ff671b;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" fill="%23fff"><path d="M24 0C10.7 0 0 10.7 0 24S10.7 48 24 48H69.5c3.8 0 7.1 2.7 7.9 6.5l51.6 271c6.5 34 36.2 58.5 70.7 58.5h121c-.5-5.3-.7-10.6-.7-16c0-10.9 1-21.6 2.9-32H199.7c-11.5 0-21.4-8.2-23.6-19.5L170.7 288H339.2c9.2-18 21.4-34.2 36-48H161.6L131.1 80H520.7L490.5 192.1c1.8-.1 3.7-.1 5.5-.1c14.8 0 29.1 1.8 42.8 5.2L569.7 82.4C576.6 57 557.4 32 531.1 32h-411C111 12.8 91.6 0 69.5 0H24zM176 512a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM352 368a144 144 0 1 0 288 0 144 144 0 1 0 -288 0zm208 16H512v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V384H432c-8.8 0-16-7.2-16-16s7.2-16 16-16h48V304c0-8.8 7.2-16 16-16s16 7.2 16 16v48h48c8.8 0 16 7.2 16 16s-7.2 16-16 16z"/></svg>');
    color: white;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center;
}

div.add-to-cart-button-with-quantity button.add-to-cart-button-submit.with-icon.loading,
div.add-to-cart-button-with-quantity button.add-to-cart-button-submit.with-icon.loading:hover {
    background-image: url('../../images/icons/double-ring-loading-icon.svg') !important;
}

div.add-to-cart-button-with-quantity button.add-to-cart-button-submit.with-icon:hover {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" fill="%23333"><path d="M24 0C10.7 0 0 10.7 0 24S10.7 48 24 48H69.5c3.8 0 7.1 2.7 7.9 6.5l51.6 271c6.5 34 36.2 58.5 70.7 58.5h121c-.5-5.3-.7-10.6-.7-16c0-10.9 1-21.6 2.9-32H199.7c-11.5 0-21.4-8.2-23.6-19.5L170.7 288H339.2c9.2-18 21.4-34.2 36-48H161.6L131.1 80H520.7L490.5 192.1c1.8-.1 3.7-.1 5.5-.1c14.8 0 29.1 1.8 42.8 5.2L569.7 82.4C576.6 57 557.4 32 531.1 32h-411C111 12.8 91.6 0 69.5 0H24zM176 512a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM352 368a144 144 0 1 0 288 0 144 144 0 1 0 -288 0zm208 16H512v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V384H432c-8.8 0-16-7.2-16-16s7.2-16 16-16h48V304c0-8.8 7.2-16 16-16s16 7.2 16 16v48h48c8.8 0 16 7.2 16 16s-7.2 16-16 16z"/></svg>');
}

div.add-to-cart-button-with-quantity.added-to-cart:after {
    border: 2px solid #06a02e;
}

div.add-to-cart-button-with-quantity.added-to-cart button.add-to-cart-button-submit.with-icon {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" fill="%23fff"><path d="M24 0C10.7 0 0 10.7 0 24S10.7 48 24 48H69.5c3.8 0 7.1 2.7 7.9 6.5l51.6 271c6.5 34 36.2 58.5 70.7 58.5h121c-.5-5.3-.7-10.6-.7-16c0-10.9 1-21.6 2.9-32H199.7c-11.5 0-21.4-8.2-23.6-19.5L170.7 288H339.2c9.2-18 21.4-34.2 36-48H161.6L131.1 80H520.7L490.5 192.1c1.8-.1 3.7-.1 5.5-.1c14.8 0 29.1 1.8 42.8 5.2L569.7 82.4C576.6 57 557.4 32 531.1 32h-411C111 12.8 91.6 0 69.5 0H24zM176 512a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM640 368a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zm-99.3-43.3c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-72 72c-6.2 6.2-16.4 6.2-22.6 0l-40-40c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L480 385.4l60.7-60.7z"/></svg>');
}

div.add-to-cart-button-with-quantity.added-to-cart button.add-to-cart-button-submit.with-icon:hover {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" fill="%23333"><path d="M24 0C10.7 0 0 10.7 0 24S10.7 48 24 48H69.5c3.8 0 7.1 2.7 7.9 6.5l51.6 271c6.5 34 36.2 58.5 70.7 58.5h121c-.5-5.3-.7-10.6-.7-16c0-10.9 1-21.6 2.9-32H199.7c-11.5 0-21.4-8.2-23.6-19.5L170.7 288H339.2c9.2-18 21.4-34.2 36-48H161.6L131.1 80H520.7L490.5 192.1c1.8-.1 3.7-.1 5.5-.1c14.8 0 29.1 1.8 42.8 5.2L569.7 82.4C576.6 57 557.4 32 531.1 32h-411C111 12.8 91.6 0 69.5 0H24zM176 512a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM640 368a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zm-99.3-43.3c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-72 72c-6.2 6.2-16.4 6.2-22.6 0l-40-40c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L480 385.4l60.7-60.7z"/></svg>');
}


div.add-to-cart-button-with-quantity.added-to-cart button.add-to-cart-button-submit {
    background-color: #06a02e;
}

div.add-to-cart-button-with-quantity .add-to-cart-button-delete-button {
    position: absolute;
    border: 0;
    top: 0;
    left: 10px;
    width: 80%;
    height: 24px;
    line-height: 24px;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 12px;
    padding: 0 16px 0 0;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M24 0C10.7 0 0 10.7 0 24S10.7 48 24 48H69.5c3.8 0 7.1 2.7 7.9 6.5l51.6 271c6.5 34 36.2 58.5 70.7 58.5h121c-.5-5.3-.7-10.6-.7-16c0-10.9 1-21.6 2.9-32H199.7c-11.5 0-21.4-8.2-23.6-19.5L170.7 288H339.2c9.2-18 21.4-34.2 36-48H161.6L131.1 80H520.7L490.5 192.1c1.8-.1 3.7-.1 5.5-.1c14.8 0 29.1 1.8 42.8 5.2L569.7 82.4C576.6 57 557.4 32 531.1 32h-411C111 12.8 91.6 0 69.5 0H24zM176 512a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm320 0a144 144 0 1 0 0-288 144 144 0 1 0 0 288zm59.3-180.7L518.6 368l36.7 36.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L496 390.6l-36.7 36.7c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L473.4 368l-36.7-36.7c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L496 345.4l36.7-36.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"/></svg>');
    background-repeat: no-repeat;
    background-position: 95% 5px;
    background-size: 16px;
    background-color: #fff;
    color: #ff671b;
}

div.add-to-cart-button-with-quantity .add-to-cart-button-delete-button:hover {
    text-decoration:underline;
}