.cart-list {
    
}

.cart-list-buttons {
    
}

@media (max-width: 767px) {
    .cart-list thead {
        display: none;
    }
    
    .cart-list th,
    .cart-list td {
        display: block;
    }
    
    .cart-list tr td:last-child {
        border-bottom: 3px solid #ccc;
    }
    
    .cart-list tr:last-child td:last-child {
        border-bottom: 0;
    }
}