.diagram-image {
    border: 1px solid #D9D9D9;
}

.diagram-image__header {
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #D9D9D9;
    font-size: 12px;
    padding: 0 5px;
}

.diagram-image__controls {
    display: flex;
    gap: 5px;
    align-items: center;
}

.diagram-image__button {
    border: none;
    background: #7B7B7B;
    fill: #fff;
    width: 36px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.diagram-image__button svg {
    width: 16px;
    height: auto;
}

.diagram-image__container {
    position: relative;
    overflow: hidden;
    height: 262px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    cursor: move;
}

.diagram-image__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(1);
    transition: transform 200ms ease-out, position 200ms ease-out;
    position: absolute;
    height: auto;
    width: 320px;
}

.diagram-image__img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.diagram-image__position {
    position: absolute;
    text-align: center;
    height: 30px;
    width: 30px;
    margin-left: -10px;
    margin-top: -10px;
    cursor: pointer;
    border-radius: 15px;
    border: 2px solid #222;
}

.diagram-image__position-highlighted {
    border-color: #0078F9;
    background: rgba(0,140,231,0.1);
}

.diagram-image__position-active {
    border-color: #ef6210;
}

@media (min-width: 768px) {
    .diagram-image__wrapper {
        width: 430px;
    }

    .diagram-image__container {
        height: 320px;
    }
}