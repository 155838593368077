.modelselection {
    position: relative;
    z-index: 10;
}

.modelselection-btn {
    position: relative;
    z-index: 2;
}

.modelselection-close {
    position: absolute;
    top: 5px;
    right: 10px;
    color: red;
}

.modelselection-current {
    border-bottom: 1px solid #ccc;
    padding: 0 0 10px 0;
    margin: 0 0 5px 0;
}

.modelselection-current {
    height: 45px;
}

.modelselection-model-image {
    float: right;
    margin-top: -65px;
    text-align: right;
}

.modelselection-model-image img {
    max-width: 100%;
}

.modelselection-list {
    list-style: none;
    margin: 10px 0 10px 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
}

.modelselection-list li {
    border: 1px solid #979797;
    border-radius: 2px;
}

.modelselection-list-period li,
.modelselection-list-transmission li {
    width: 50%;
}

.modelselection-list a {
    display: block;
    padding: 7px;
}

.modelselection-list a:hover {
    background-color: #eee;
}

.modelselection-footer {
    padding: 10px 0 0 0;
    border-top: 1px solid #ccc;
}

.modelselection-back .icon {
    margin-right: 10px;
}

@media (max-width: 767px) {
    .modelselection-box {
        max-width: 100%;
    }

    button.btnCarSelection {
        height: inherit !important;
        text-align: center !important;
    }

    button.btnCarSelection span {
        margin-top: 10px;
        margin-left: 0 !important;
        display: block;
    }
}
