.diagram {
    display: flex;
    gap: 24px;
    flex-direction: column;
    margin-top: 20px;
}

.diagram__image-col {
    flex: 1 1 38%;
}

.diagram__option-list {
    width: 100%;
    padding: 1em;
    background-color: #fff;
    margin-bottom: 2em;
}

.diagram__option-list > ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px; /* Add spacing between items */
    padding: 0;
    margin: 0;
}

.diagram__option-list > ul > li {
    display: flex;
    align-items: center; /* Vertically align checkbox and text */
}

.diagram__option-list > ul > li > input {
    margin-right: 1em;
}

.diagram__option-list > ul > li > label {
    display: inline;
    margin-bottom: 0;
    padding-top: 4px;
}

div.diagram-parts {
    position: relative;
}

.diagram-parts-working-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    opacity: 0;
    transition: opacity 0.6s ease;
    pointer-events: none;
}

.diagram-parts-working-overlay.active {
    opacity: 1;
    pointer-events: auto;
    height: auto;
    bottom: 0;
}

.diagram-parts-working-overlay::after {
    content: "Bezig met bijwerken...";
    position: absolute;
    left: 50%;
    transform: translateX(-50%); /* Horizontal center */
    top: var(--text-top, 50%); /* Dynamic top from JS, fallback to 50% */
    color: white;
    font-size: 18px;
    text-align: center;
}

tr.diagram-parts__row_has-comment {
    border-bottom: 0;
}

tr.diagram-parts__row_has-comment td {
    padding-bottom: 0;
}
tr.diagram-parts__comment {
    background: none;
}
tr.diagram-parts__comment td {
    padding: 0.1em 0 0.7em 0.7em;
}
tr.diagram-parts__comment td span {
    opacity: 0.6;
    font-size: 0.8em;
}

@media (min-width: 1024px) {
    .diagram {
        flex-direction: row;
    }
}
