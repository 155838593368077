.search-box {
    background: #FF671B;
}

.modal .search-box {
    padding: 30px;
}

.search-box__section {
    margin: 0 0 12px 0;
}

.search-box h5 {
    color: #fff;
    font-size: 15px;
    display: flex;
    align-items: center;
}

.search-box h5 .icon-question-small {
    margin-left: 3px;
    transform: translateY(-5px);
}

.search-box__extend,
.search-box__extend:hover {
    color: #fff;
    font-size: 15px;
    cursor: pointer;
    text-decoration: underline;
}

@media (min-width: 768px) {
    .search-box h5 {
        font-size: 17px;
    }
}