.template-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    z-index: 10;
}

.template-header__main-bar {
    display: flex;
    flex-wrap: wrap;
    padding: 13px 30px 13px 14px;
    background: #000;
}

.template-header__nav-bar-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 34px;
    position: relative;
}

.template-header__nav-search-toggle {
    background: none;
    border: none;
    width: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.template-header__nav-search-toggle svg {
    fill: #FF671B;
    height: 24px;
    width: auto;
}

.template-header__nav-bar-icon-wrapper {
    transform: scale(0.6) skew(-10deg);
    margin-bottom: 10px;
}

.template-header__nav-bar-toggle-text {
    font-size: 12px;
    color: #fff;
    position: absolute;
    bottom: 0;
}

.template-header__logo {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.template-header__logo img {
    width: 136px;
    height: auto;
}

.template-header__search-form {
    width: 350px;
    background-color: #000;
    color: #fff;
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    top: 65px;
    height: 0;
    overflow: hidden;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.template-header__search-form--active {
    height: 230px;
    overflow: visible;
}

.template-header__search-form-title {
    font-size: 20px;
}

.template-header__search-form-text {
    margin: 6px 0;
}

.template-header__login-form {
    display: none;
    position: absolute;
    width: 440px;
    max-width: 100%;
    top: 100%;
    right: 0;
    background: #fff;
    padding: 30px;
    border: 1px solid #dbdbdb;
}

.template-header__login-form--visible {
    display: block;
}

.template-header__cart-dropdown {
    position: fixed;
    left: 0;
    top: 0;
    width: 90%;
    z-index: 3;
}

/* Tablet */
@media (min-width: 768px) {
    .template-header {
        position: static;
        max-width: 1280px;
        margin-right: -15px;
        margin-left: -15px;
    }

    .template-header__nav-bar-icon-wrapper {
        position: relative;
        left: -8px;
        top: 1px;
    }

    .template-header__nav-bar-toggle {
        flex-direction: row-reverse;
        width: 95px;
    }

    .template-header__nav-bar-toggle-text {
        font-size: 15px;
    }

    .template-header__logo {
        justify-content: flex-start;
    }

    .template-header__logo > img {
        width: auto;
        height: 40px;
    }

    .template-header__cart {
        height: 50px;
        width: 50px;
        margin: 0 0 0 8px;
        position: relative;
    }

    .template-header__cart .cart-btn {
        height: 42px;
    }

    .template-header__cart > svg {
        width: 33px;
        height: 27px;
        position: relative;
        left: -6px;
    }

    .template-header__cart-product-count {
        height: 19px;
        width: 19px;
        font-size: 10px;
    }

    .template-header__cart-dropdown {
        position: absolute;
        right: 0;
        top: 62px;
        left: auto;
        width: 364px;
        height: 561px;
        z-index: 3;
    }

}

/* Desktop small */
@media (min-width: 1024px) {
    .template-header {
        margin: 0 auto 20px auto;
    }

    .template-header__logo {
        margin: 0;
        flex-grow: 0;
    }

    .template-header__main-bar {
        background: #fff;
        height: 50px;
        flex-wrap: nowrap;
        align-items: center;
        padding: 0 30px;
        margin: 16px 0 0;
    }

    .template-header__nav-bar {
        display: flex;
        align-items: center;
        flex-grow: 1;
        margin: 0 28px;
    }

    .template-header__nav-bar-toggle {
        display: none;
    }

    .template-header__search-form {
        height: auto;
        top: -37px;
        left: 44px;
        position: relative;
        background-color: #fff;
        overflow: visible;
        padding: 0;
    }

    .template-header__cart .cart-btn {
        height: 100%;
    }
}

/* Desktop medium */
@media (min-width: 1170px) {
    .template-header__search-form {
        top: 0;
        left: 0;
    }
}

/* Desktop large */
@media (min-width: 1280px) {

}

@media (max-width: 1024px) {
    .template-header__search-form {
        top: 65px !important;
        left: 0 !important;
        width: 100%;
    }
}