.body--menu-active {
   overflow: hidden;
}

.template-header__top-bar {
   height: calc(100vh - 65px);
   position: fixed;
   background: #FF671B;
   z-index: 10;
   width: 100%;
   left: 0;
   right: 0;
   bottom: 0;
   top: 68px;
   display: flex;
   flex-direction: column;
   transform: translateX(-100%);
   transition: transform 0.2s;
   overflow: scroll;
   padding: 20px;
   font-size: 16px;
}

.template-header__top-bar--active {
   transform: translateX(0);
}

.template-header__slogan {
   color: #b0b0b0;
   font-size: 13px;
}

.template-header__quick-nav {
}

.template-header__quick-nav-item {
   margin: 0 0 16px 0;
}

.template-header__quick-nav-item a {
   text-decoration: none;
   color: #fff;
}

.template-header__icon-nav {
   display: flex;
   flex-direction: column;
   padding-top: 20px;
   border-top: 1px solid rgba(0,0,0,0.2);
}

.template-header__icon-nav-item {
   display: flex;
   align-items: center;
   margin: 0 0 16px 0;
}

.template-header__icon-nav-item:last-of-type {
   margin: 0;
}

.template-header__icon-nav-item a {
   display: flex;
   align-items: center;
   color: #fff;
   transition: color 0.2s;
}

.template-header__icon-nav-item a:hover {
   color: #FF671B;
   text-decoration: none;
}

.template-header__icon-nav-item svg {
   margin: 0 12px 0 0;
   fill: #fff;
   width: 22px;
   height: auto;
}

/* Tablet */
@media (min-width: 768px) {
   .template-header__top-bar {
      height: calc(100vh - 65px);
   }
}

/* Desktop small */
@media (min-width: 1024px) {
   .template-header__top-bar {
      position: relative;
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 50px;
      border-bottom: 1px solid #dbdbdb;
      padding: 0 30px;
      background: #fff;
      transform: none;
      overflow: visible;
      top: 0;
   }

   .template-header__mobile-menu {
      display: none;
   }

   .template-header__icon-nav {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin: 0 0 0 auto;
      border-top: none;
      padding: 0;
   }

   .template-header__icon-nav-item {
      display: flex;
      align-items: center;
      font-size: 15px;
      margin: 0 19px 0 0;
   }

   .template-header__icon-nav-item:last-of-type {
      margin: 0;
   }

   .template-header__icon-nav-item a {
      display: flex;
      align-items: center;
      color: #211f1f;
      transition: color 0.2s;
   }

   .template-header__icon-nav-item a:hover {
      color: #FF671B;
      text-decoration: none;
   }

   .template-header__icon-nav-item svg {
      margin: 0 8px 0 0;
      fill: #FF671B;
      max-width: 19px;
   }

   .template-header__quick-nav {
      display: flex;
      flex-direction: row;
      font-size: 13px;
      margin: 0 0 0 auto;
   }

   .template-header__quick-nav-item {
      margin: 0 18px 0 0;
   }

   .template-header__quick-nav-item a {
      color: #8f8f8f;
      cursor: pointer;
      transition: color 0.2s;
   }

   .template-header__quick-nav-item a:hover {
      color: #FF671B;
   }

   .template-header__icon-nav {
      margin: 0 0 0 28px;
   }
}
