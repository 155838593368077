.diagram-parts {
    border: 1px solid #D9D9D9;
    background: #fff;
}

.diagram-parts__table {
    width: 100%;
}

.diagram-parts__table thead {
    position: sticky;
    top:0;
    left:0;
    z-index: 10;
}

.diagram-parts__head {
    background: #D9D9D9;
}

.diagram-parts td,
.diagram-parts th {
    padding: 15px 12px;
    font-size: 13px;
}

.diagram-parts__head th {
    padding: 9px 12px;
    white-space: nowrap;
}

.diagram-parts__row:hover td {
    background: #ccc;
}

.diagram-parts__price-cell {
    text-align: center;
    white-space: nowrap;
}

.diagram-parts__row {
    background: #f3f3f3;
}

.diagram-parts__row-incompatible {
    opacity: 0.6;
}

.diagram-parts__row-group-title {
    background: #f3f3f3;
    border-top: 1px solid #d9d9d9d9;
}

.diagram-parts__row-group-title td {
    padding: 6px 12px;
}

.diagram-parts__row--group {
    background: #fdfdfd;
    cursor: pointer;
}

.diagram-parts__row--highlighted td, .diagram-parts__row--highlighted:hover td {
    background: #d5f5ff;
}

.diagram-parts__row--active td, .diagram-parts__row--active:hover td {
    background: #ef621082;
}

.diagram-parts__expand-cell svg {
    width: 8px;
}

.diagram-parts__row:not(.collapsed) .diagram-parts__expand-cell svg {
    transform: scaleY(-1);
}

.diagram-parts__position {
    white-space: nowrap;
}

.diagram-parts__product-meta {
    font-size: 13px;
}

.diagram-parts__stock-cell .product-stock__quantity {
    display: none;
}

.diagram-parts__product-details {
    padding: 17px;
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    gap: 30px;
    flex-direction: column-reverse;
}

.diagram-parts__product-details-description {
    margin-top: 16px;
    white-space: normal;
}

.diagram-parts__price-mobile {
    display: block;
    font-weight: bold;
}

.diagram-parts__product-image {
    flex-shrink: 0;
}

.diagram-parts__messages {
    border-bottom: 1px solid #d9d9d9;
}

.diagram-parts__messages td {
    border-bottom: 1px solid #d9d9d9;
    padding-top: 0;
    padding-bottom: 0;
}

.diagram-parts__messages .alert {
    padding: 2px;
    margin-bottom: 0;
    line-height: 1.6em;
    margin-bottom: 15px;
}

@media (max-width: 768px) {
    .diagram-parts__price-cell {
        padding: 0 !important;
    }
}

@media (max-width: 767px) {
    .diagram-parts__table thead {
        top: 68px;
    }
}

@media (min-width: 768px) {
    .diagram-parts {
        flex: 1 1 62%;
        max-height: calc(100vh - 200px);
        min-height: 360px;
        overflow-y: scroll;
    }

    .diagram-parts td,
    .diagram-parts th {
        font-size: 15px;
    }

    .diagram-parts__cart-cell {
        min-width: 155px;
    }

    .diagram-parts__price-mobile {
        display: none;
    }

    .diagram-parts__product-details {
        flex-direction: row;
    }

    .diagram-parts__product-details-info {
        max-width: 290px;
    }
}
