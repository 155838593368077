.cart {}

.cart__title {
    background: #f3f3f3;
    height: 60px;
    line-height: 60px;
    font-size: 18px;
    margin: 0 0 20px 0;
    padding: 0 20px;
}

.cart__top-bar {
    padding: 0 20px;
}

.cart__free-delivery {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    color: #06a02e;
}

.cart__free-delivery > svg {
    height: 21px;
    margin: 0 11px 0 0;
    fill: #06a02e;
    position: relative;
    top: -2px;
}

.cart__top-bar-order-btn {
    margin: 0 16px 0 0;
    display: flex;
    background-color: #06A02E;
}

.cart__top-bar-order-btn:after {
    background-color: #06A02E;
}

.cart__check-cart-msg {
    font-size: 13px;
    font-weight: 600;
    color: #211f1f;
    margin: 24px 0 0;
}

.cart__wrapper {
    padding: 0 20px;
    margin-bottom: 40px;
}

.cart__content {
    flex-grow: 1;
    padding-right: 20px;
}

.cart__sidebar {
    position: sticky;
    z-index: 1;
    top: 0;
    margin: 0;
    width: 30%;
    padding: 0 20px;
}

.cart__usp {
    margin: 35px 0 0;
    padding: 0 0 35px;
    border-bottom: 3px solid #f3f3f3;
}

.cart__usp:last-of-type {
    border: none;
}

.cart__usp h4 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}

.cart__usp-list {
    list-style: none;
    margin: 20px 0 0;
    font-size: 16px;
    font-weight: 500;
    padding: 0;
}

.cart__usp-list-item {
    margin: 17px 0 0;
    display: flex;
    align-items: center;
}

.cart__usp-list-item:first-of-type {
    margin: 0;
}

.cart__usp-list-item > svg {
    height: 16px;
    fill: #ff7505;
    margin: 0 19px 0 0;
}

.cart__usp-icons {
    display: flex;
    justify-content: space-around;
}

.cart__usp-icon {
    display: flex;
    flex-direction: column;
}

.cart__usp-icon > svg {
    height: 34px;
}

.cart__usp-icon-text {
    margin: 12px 0 0;
    color: #FF671B;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.cart__reminder {
    padding: 0;
    justify-content: center;
    flex-direction: row;
    flex-grow: 1;
}

.cart__reminder-text {
    font-weight: bold;
    color: #211f1f;
    line-height: 1.15;
    display: flex;
    align-items: center;
}

.cart__reminder-text > svg {
    height: 21px;
    fill: #FF671B;
    margin: 0 9px 0 0;
}

.cart__reminder-show-up-sell {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    color: #333;
    margin: 16px 0 0;
    cursor: pointer;
}

.cart__reminder-show-up-sell > svg {
    height: 12px;
    fill: #FF671B;
    margin: 0 0 0 13px;
}

.cart__totals {
    flex-grow: 1;
    padding: 20px;
    background: #f3f3f3;
}

.cart__back-btn {
    display: inline;
    color: #FF671B;
    margin: 20px 0;
    font-size: 16px;
    font-weight: 600;
}

.cart__back-btn > svg {
    height: 12px;
    width: 7px;
    margin: 0 16px 0 0;
    fill: #ff6e0f;
}

.cart__share {
    padding-left: 0;
    float: right;
    line-height: 1;
}

.cart__share a {
    color: #999;
    font-size: 0.8em;
}

.cart__share span.cart__share_link_anchor {
    color: #999;
    vertical-align: middle;
}

.cart__share span.cart__share_icon svg {
    fill: #ff671b;
    height: 17px;
    vertical-align: middle;
}

.cart__share span.cart__share_social_icon svg {
    fill: #fff;
    height: 6px;
    position: relative;
    top: -4px;
    left: -11px;
}

.cart__share_link {
    display: none;
}

.cart__share_link {
    margin-top: 10px;
    margin-bottom: 2em;
}

.cart__share_link .form__group {
    display: flex;
    margin-top: 20px;
    width: 90%;
}

.cart__share_link .form__control {
    height: 50px;
}

.cart__share_link .form__group label.form__input-label {
    font-size: 12px;
}

.cart__share_link .cart__share_link_copy_button {
    display: flex;
    height: 32px;
    width: 32px;
    padding: 1px;
    margin-top: 12px;
    margin-left: 10px;
    cursor: pointer;
}
.cart__share_link .cart__share_link_copy_button svg {
    fill: #666;
    height: 24px;
    width: 24px;
}

.cart__share_link .cart__share_link_copy_copied_text_span {
    margin-left: 10px;
    padding-top: 12px;
    visibility: hidden;
}

.cart__share_link .cart__share_link_copy_copied_text {
    color: #06a02e;
    white-space: nowrap;
}

.cart__share_link a {
    align-content: center;
    margin-right: 10px;
}
.cart__share_link a svg {
    fill: #666;
    height: 24px;
    width: 24px;
}

.cart h2 {
    font-size: 18px;
}

.cart__wrapper-buttons {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px
}

.cart__totals-buttons {
    justify-content: center;
    display: flex;
}

.cart__sidebar-buttons {
    flex-shrink: 0;
    width: 30%;
    margin: 0
}

.cart__usp.kiyoh-rating {
    margin: 17px 0 17px 0;
    padding: 0;
}

.cart__usp.kiyoh-rating div.kiyoh-rating-number {
    width: 5em;
    height: 5em;
}

.cart__usp.kiyoh-rating div.kiyoh-rating-number span.rating {
    font-size: 2.0em;
}

.cart__content .cart__buttons {
    margin-top: 10px;
    margin-bottom: 40px;
}

.cart__content .content .product-tile {
    border-bottom: 0;
}

.cart__top-bar .cart__totals-buttons {
    margin-top: 0;
    position: relative;
    top: -26px;
    left: -40px;
}

/* Tablet */
@media (min-width: 768px) {
    .cart__top-bar {
        align-items: flex-end;
    }
}

/* Desktop small */
@media (min-width: 1024px) {
}

/* Desktop medium */
@media (min-width: 1170px) {
    .cart__title {
        background: transparent;
        height: auto;
        margin: 0;
    }

    .cart__check-cart-msg {
        display: none;
    }

    .cart__free-delivery {
        font-size: 16px;
    }

    .cart__free-delivery > svg {
        margin: 0 7px 0 0;
        position: relative;
        top: -2px;
    }

    .cart__rows {
        margin: 0;
    }

    .cart__wrapper {
        display: flex;
        align-items: flex-start;
    }

    .cart__sidebar {
        flex-shrink: 0;
        border: 3px solid #f3f3f3;
    }

    .cart__totals-wrapper {
        display: flex;
    }

    .cart__reminder {
        display: flex;
    }

    .cart__totals {
        max-width: 540px;
        margin: 27px 0 0 auto;
        padding: 20px;
    }

    .cart__totals-buttons {
        display: flex;
        flex-direction: row-reverse;
        margin: 24px 0 0;
    }
}

/* Desktop large */
@media (min-width: 1280px) {
}

@media (max-width: 1170px) {
    #cart-reminder .hidden-xs, #cart-reminder {
        display: none !important;
    }
    .cart__top-bar {
        padding: 0 20px;
    }

    .cart__sidebar {
        position: inherit;
        z-index: inherit;
        top: inherit;
        padding: 0;
        width: inherit;
    }

    .cart__top-bar .cart__totals-buttons {
        margin-top: 0;
        float: right;
        position: relative;
        top: -60px;
        left: 0;
    }

    .cart__totals-buttons {
        margin-top: 20px;
    }
}

@media (max-width: 1024px) {
    .cart__top-bar, .cart__wrapper {
        padding: 0 10px;
    }

    .cart__buttons {
        margin-top: 10px;
    }

    .cart__share {
        font-size: 1.23em;
    }

    .cart__content .content .product-tiles {
        display: flex;
        overflow: auto;
    }

    .cart__content .content .product-tile {
        height: 170px;
        margin-right: 20px;
        border-right: 1px solid #eee;
    }

    .cart__top-bar .cart__totals-buttons {
        margin-top: 20px;
        position: relative;
        top: 0;
        left: 0;
        float: none;
    }

    .cart__sidebar-buttons {
        width: 100%;
        margin-top: 20px;
    }

    .product-stock {
        align-items: start;
        white-space: nowrap;
    }

    .product-tile__name {
        display: block;
        height: 57px;
    }

    .product-tile__price {
        position: relative;
        bottom: -42px;
    }

    .cart__wrapper-buttons .cart__content {
        display: none;
    }
}

@media (max-width: 767px) {
    .cart__top-bar-order-btn {
        width: 80%;
    }
    .cart__content .cart-rows {
        margin-top: 10px;
    }
}

@media (max-width: 575.98px) {
    .cart__top-bar-order-btn {
        width: 100%;
    }

    .cart__share_link .cart__share_link_copy_copied_text_span {
        position: absolute;
        top: 44px;
    }
}