body.homepage .template-content, body.categorypage .template-content {
    margin-left: 0;
}

@media (min-width: 1024px) {
    .template-content {
        max-width: 1280px;
        margin: 0 auto !important;
    }
}

