.product-details {
    display: flex;
    flex-direction: column;
}

.product-details__title {
    font-size: 18px;
    margin: 28px 0 0;
    font-weight: 600;
    padding: 0 13px;
}

.product-details__title--desktop,
.product-details__deposit-text--desktop {
    display: none;
}

.product-details__price {
    margin: 10px 0 0;
    padding: 0 13px;
}

.product-details__price--desktop {
    display: none;
}

.product-details__default-amount {
    text-decoration: line-through;
    margin-right: 20px;
}

.product-details__price-box {
    display: flex;
    align-items: flex-end;
}

.product-details__price-amount {
    color: #ff7701;
    font-size: 14.5px;
    line-height: 19px;
    font-weight: 500;
}

.product-details__price-text {
    color: #7b7b7b;
    margin: 0 0 0 6px;
    font-size: 12px;
}

.product-details__deposit-text {
    font-size: 12px;
    width: 60%;
    color: #7b7b7b;
    margin: 5px 0 0 12px;
}

.product_details__photos {
    margin: 5px 0 0;
    position: relative;
}

.product-details__summary {
    overflow: hidden;
}

.product-details__share {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 20px;
}

.product-details__share-text {
    font-size: 13px;
    color: #979797;
    margin: 0 0 0 auto;
}

.product-details__share-icon {
    margin: 0 0 0 6px;
    display: flex;
}

.product-details__share-icon svg {
    width: 18px;
    height: 19px;
    fill: #ff6d12;
}

.product-details__share-modal {
    position: fixed;
    background: rgba(0,0,0,0.6);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
}

.product-details__documents {
    margin-top: 10px;
    margin-bottom: 2em;
}


.addthis_native_toolbox {
    background: white;
    padding: 20px;
}

.product-details__tabs {
    background-color: #f3f3f3;
}

.product-details__tabs-item--active .product-details__tabs-item-content {
    display: block;
}

.product-details__tabs-item-title {
    font-size: 15px;
    font-weight: 600;
    color: #000000;
    height: 47px;
    display: flex;
    align-items: center;
    padding: 0 14px;
    border-bottom: 1px solid #D3D3D3;
}

.product-details__tabs-item-title svg {
    width: 8px;
    height: 13px;
    transform: rotate(90deg);
    margin: 0 0 0 auto;
}

.product-details__tabs-item-title svg polygon {
    fill: #ff7701;
}

.tabs-nav-active .product-details__tabs-item-title svg {
    transform: rotate(-90deg);
}

.product-details__tabs-item-content {
    display: none;
    padding: 0 14px 14px 14px;
}

.product-details__tabs-item-content table {
    word-break: break-word;
}

.tabs-nav-active .product-details__tabs-item-content {
    display: block;
}

.product-details .search-box {
    padding: 20px;
}

.product-details__tabs-nav {
    display: none;
    background: #f3f3f3;
}

/* Tablet */
@media (max-width: 1023px) {
    .product-details__tabs-item.tabs-nav-active .product-details__tabs-item-title {
        border-bottom: 2px solid #FF671B;
    }

    .product-details__tabs-item.tabs-nav-active .product-details__tabs-item-content {
        padding-top: 10px;
        border-left: 10px solid #FF671B;
        border-bottom: 1px solid #D3D3D3;
    }
}

/* Desktop small */
@media (min-width: 1024px) {
    .product-details {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .product-details__title--mobile,
    .product-details__deposit-text--mobile {
        display: none;
    }

    .product-details__title--desktop,
    .product-details__deposit-text--desktop {
        display: block;
    }

    .product-details__title {
        font-size: 24px;
        font-weight: bold;
        line-height: 0.79;
    }

    .product-details__price--mobile {
        display: none;
    }

    .product-details__price--desktop {
        display: block;
    }

    .product-details__price {
        margin: 14px 0 0;
    }

    .product-details__price-amount {
        font-size: 21.5px;
        font-weight: 500;
        line-height: 0.88;
    }

    .product-details__price-text {
        font-size: 15px;
        font-weight: 600;
        line-height: 1;
        margin: 0 0 0 10px;
    }

    .product-details__deposit-text {
        font-size: 15px;
        width: 60%;
        color: #7b7b7b;
        margin: 5px 0 0 10px;
    }

    .product_details__photos {
        margin: 19px 0 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        width: 40%;
    }

    .product_details__slider-wrapper {
        order: 1;
        display: flex;
        justify-content: center;
    }

    .product-details__share {
        order: 2;
        margin: 20px 0 0;
        flex-direction: row-reverse;
        justify-content: flex-end;
    }

    .product-details__share-text {
        margin: 0 0 0 9px;
        font-size: 15px;
        font-weight: 600;
    }

    .product-details__share-icon {
        margin: 0;
    }

    .product-details__summary {
        width: 60%;
        margin: 20px 0 40px;
    }

    .product-details__suggestions {
        width: 100%;
    }

    .product-details__tabs-item-title--mobile {
        display: none;
    }

    .product-details__tabs {
        width: 100%;
        margin: 40px 0 0 0;
    }

    .product-application-search__title {
        margin: 0 0 10px 0;
        font-size: 18px;
    }

    .product-details__tabs-nav {
        display: block;
    }

    .product-details__tabs-item-content {
        padding: 40px;
    }

    .product-details__tabs-item-content--shippingcosts,
    .product-details__tabs-item-content--contact,
    .product-details__tabs-item-content--brand,
    .product-details__tabs-item-content--subscribeInStockNotification {
        padding: 40px;
    }
}

/* Desktop medium */
@media (min-width: 1170px) {

}

/* Desktop large */
@media (min-width: 1280px) {

}
