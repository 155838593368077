.template-footer {

}

.template-footer__usp {
    border-top: 1px solid #e0660b;
    background: #FF671B;
    height: 68px;
    color: #fff;
    display: flex;
    align-items: center;
}

.template-footer__newsletter-sign-up {
    background: #FF671B;
}

.template-footer__content {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}

.template-footer__section {
    width: 50%;
    margin: 30px 0 0;
}

.template-footer__container {
    background: #211f1f;
    padding: 25px 20px;
}

.template-footer__icon-nav-boxed-section {
    width: 100%;
}

.template-footer__icon-nav-boxed {
    display: flex;
    margin: 0;
    padding: 0;
    width: 100%;
}

.template-footer__icon-nav-boxed li {
    width: 50%;
    border: solid 1px #ffffff;
    list-style: none;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 2px;
}

.template-footer__icon-nav-boxed li:first-of-type {
    margin: 0 5px 0 0;
}

.template-footer__icon-nav-boxed li:last-of-type {
    margin: 0 0 0 5px;
}

.template-footer__icon-nav-boxed li a {
    display: flex;
    align-items: center;
    color: white;
    font-size: 13.5px;
    transition: color 0.2s;
}

.template-footer__icon-nav-boxed li a:hover {
    color: #FF671B;
}

.template-footer__icon-nav-boxed li a .icn {
    width: 18px;
    height: 18px;
    color: #FF671B;
    margin: 0 10px 0 0;
}

.template-footer__nav {
    padding: 0;
    margin: 0;
    list-style: none;
}

.template-footer__nav li {
    margin: 0 0 10px 0;
    padding: 0;
    font-size: 14px;
    color: #acacac;
}

.template-footer__nav a {
    color: #acacac;
    display: flex;
    align-items: center;
    transition: color 0.2s;
}

.template-footer__nav a:hover,
.template-footer__nav a.mailto:hover {
    color: #FF671B;
}

.template-footer__nav a.mailto {
    color: white;
}

.template-footer__nav a .icn {
    margin: 0 15px 0 0;
}

.template-footer__nav-title {
    color: #fff;
    display: block;
    margin: 0 0 20px 0;
}

.template-footer__icon-nav {
    list-style: none;
    margin: 0;
    padding: 0;
}

.template-footer__icon-nav li {
    margin: 0 0 25px 0;
}

.template-footer__icon-nav a {
    color: #fff;
    font-weight: normal;
    display: flex;
    align-items: center;
}

.template-footer__icon-nav a .icn {
    margin-right: 15px;
}

.template-footer__social-icons {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0;
    display: flex;
}

.template-footer__social-icons li {
    margin: 0 20px 0 0;
    padding: 0;
}

.template-footer__social-icons svg {
    fill: #FF671B;
    height: 28px;
}

.template-footer__copyright {
    margin: 52px 0 0;
    max-width: 1280px;
    color: #acacac;
}

.template-footer__quality-mark {
    width: 87px;
}

.template-footer__quality-mark img {
    height: auto;
    width: 100%;
}

/* Tablet */
@media (min-width: 768px) {
    .template-footer__container {
        padding: 0 50px;
    }

    .template-footer__section {
        width: 33.33333%;
    }

    .template-footer__content {
        padding: 0 50px;
    }
}

/* Desktop small */
@media (min-width: 1024px) {
    .template-footer__container {
        padding: 33px 0;
    }

    .template-footer__section {
        width: 20%;
        margin: 0;
    }
}

/* Desktop medium */
@media (min-width: 1170px) {

}