.modal-content {
    border-radius: 2px;
}

@media (min-width: 1024px) {
    .modal-dialog {
        width: 800px;
    }
}

.modal-backdrop {
    height: 100%;
    position: fixed;
}

.modal-sm {
    max-width: 100%;
    width: 400px;
}