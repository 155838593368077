.article p {
    line-height: 30px;
    color: #9d9d9d;
}

.article__header {
    margin-bottom: 40px;
}

.article__header-image {
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.article__header-content {
    background: #F3F3F3;
    padding: 30px 20px;
}

.article__title {
    font-size: 22px;
    font-weight: bold;
    margin-top: 0;
}

.article__date {
    color: #9d9d9d;
    font-size: 14px;
}

.article__intro {
    margin-top: 20px;
}

.article__body {
    max-width: 890px;
    margin: 0 auto;
    padding: 0 20px 100px;
}

.article__body img {
    width: 100%;
    max-width: 100%;
    height: auto !important;
}

.article__reactions {
    margin-top: 40px;
}

.article__nav {
    padding: 0 20px 50px;
}


.article__nav-link {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.article__nav-link svg {
    width: 12px;
    height: 12px;
    fill: #FF671B;
    margin-right: 10px;
}

.article__nav-link--previous {
    color: #d9d9d9;
}

.article__nav-link--previous svg {
    fill: #d9d9d9;
}


@media (min-width: 768px) {
    .article__header-content {
        padding: 50px;
    }

    .article__body {
        padding: 0 50px 100px;
    }

    .article__nav {
        display: flex;
        padding: 0 50px 50px;
    }

    .article__nav-link--next {
        margin-left: auto;
        flex-direction: row-reverse;
    }

    .article__nav-link--previous svg {
        margin-right: 20px;
    }

    .article__nav-link--next svg {
        margin-left: 20px;
    }
}

@media (min-width: 1024px) {
    .article__header {
        position: relative;
        margin-bottom: 95px;
    }

    .article__header-image {
        height: 478px;
    }

    .article__header-content {
        max-width: 685px;
        padding: 70px 0 70px 70px;
        position: absolute;
        left: 0;
        bottom: -30px;
        z-index: 2;
    }

    .article__header-content:after {
        content: '';
        background-color: #F3F3F3;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -70px;
        width: 200px;
        transform: skew(-8deg);
        z-index: -1;
    }
}
