.cart-rows {
}

.cart-rows__row {
    padding: 10px 0;
    position: relative;
}

.cart-rows__row_footer {
    border-bottom: 1px solid #d3d3d3;
}

.cart-rows__row:first-of-type {
    border-top: 1px solid #d3d3d3;
}

.cart-rows__delete {
    color: #d60000;
    display: inline-block;
    margin-top: 8px;
}

.cart-rows__column--product {
    display: flex;
}

.cart-rows__product-name {
    flex-grow: 1;
}

.cart-rows__product-name a {
    color: #211F1F;
}

.cart-rows__product-image-container {
    margin: 0 10px 0 0;
    width: 70px;
    height: 70px;
    flex-shrink: 0;
}

.cart-rows__product-image {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.cart-rows__product-remove-icon {
    width: 20px;
    height: 16px;
    position: relative;
    top: -2px;
}

.cart-rows__product-remove-icon > svg {
    width: 100%;
    height: 100%;
    fill: red;
}

.cart-rows__sku {
    font-size: 12px;
    color: #999;
}

.attached-item {
    margin-top: 10px;
    margin-left: 30px;
    margin-bottom: 30px;
}

.attached-header {
    background: #f3f3f3;
    padding: 10px 20px;
}

.cart-rows__row.attached {
    background: #fff;
    border: 1px solid #f3f3f3;
}

.cart-rows__row_footer_separator {
    border-bottom: 1px solid #d3d3d3;
}

@media (max-width: 767px) {
    .cart-rows__sku,
    .cart-rows__row--header,
    .cart-rows__column--price {
        display: none;
    }

    .cart-rows__row {
        padding: 10px 20px;
    }

    .cart-rows__column--quantity {

    }

    .cart-rows__column--total {
        position: absolute;
        bottom: 46px;
        right: 20px;
        font-weight: bold;
        font-size: 17px;
    }

    .cart-rows__column--stock {
        position: absolute;
        bottom: 50px;
        left: 100px;
    }

    .cart-rows__column--delete {
        position: absolute;
        right: 20px;
        bottom: 10px;
    }
}

@media (min-width: 768px) {
    .cart-rows__row {
        display: flex;
    }

    .cart-rows__row:first-of-type {
        border-top: none;
    }

    .cart-rows__column {
        flex-shrink: 0;
        line-height: 46px;
        align-items: center;
        align-self: center;
    }

    .cart-rows__column--product {
        flex-shrink: 1;
        flex-grow: 1;
    }

    .cart-rows__product-name {
        padding-top: 11px;
        line-height: 23px;
    }

    .cart-rows__column--header {
        font-weight: bold;
    }

    .cart-rows__row--header {

    }

    .cart-rows__column--stock {
        width: 140px;
    }

    .cart-rows__column--quantity {
        width: 140px;
    }

    .cart-rows__column--price {
        width: 90px;
    }

    .cart-rows__column--total {
        width: 90px;
    }

    .cart-rows__column--delete {
        width: 25px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .cart-rows__column--sku,
    .cart-rows__column--price {
        display: none;
    }
}
