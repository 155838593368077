.cart-totals {
}

.cart-totals table {
    width: 100%;
}

.cart-totals th,
.cart-totals td {
    padding: 4px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    min-width: 100px;
}

.cart-totals__total th,
.cart-totals__total td {
    font-weight: bold;
    font-size: 16px;
    border-top: 1px solid #d3d3d3;
}

.cart-totals__next-btn {
    margin: 15px 0 0 0;
}

.cart-totals .shipment-country-select {
    display: inline-block;
    padding: 0.2em;
    margin-left: 1em;
    border: 1px solid #dcdcdc;
}

@media (max-width: 767px) {
    .cart-totals__nav {
        text-align: center;
    }

    .cart-totals__continue-btn {
        display: inline-block;
        margin: 20px 0 0 0;
    }

    .cart__totals {
        margin: 0;
    }
}

/* Tablet */
@media (min-width: 768px) {
    .cart-totals th,
    .cart-totals td {
        padding: 8px 0;
    }

    .cart-totals__nav {
        display: flex;
        flex-direction: row-reverse;
    }

    .cart-totals__continue-btn {
        margin: 0 auto 0 0;
        display: flex;
        align-items: center;
        color: #7b7b7b;
        font-size: 16px;
    }
}

/* Desktop small */
@media (min-width: 1024px) {
}

/* Desktop medium */
@media (min-width: 1170px) {
}

/* Desktop large */
@media (min-width: 1280px) {
}
