.cart-btn {
    width: 50px;
    height: 42px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart-btn--large {
    width: 70px;
    height: 50px;
}

.cart-btn:hover:after{
    background: #000;
}

.cart-btn:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #FF671B;
    transform: skew(-10deg);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: background-color .2s;
}

.cart-btn__icn.cart-btn__icn {
    fill: #fff;
    width: 26px;
    height: 22px;
    position: relative;
}

.cart-btn__product-count {
    color: #fff;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.10);
    background: #06A02E;
    border-radius: 50%;
    width: 21px;
    height: 21px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -7px;
    right: -7px;
    transition: background .2s;
}

.cart-btn:hover .cart-btn__product-count {
    background: #FF671B;
}