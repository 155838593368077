#diagramTabContents .diagram-selector {
    margin-top: -1em;
    overflow: hidden;
}
#diagramTabContents .diagram-selector .diagrams {
    position: relative;
    margin-top: 1em;
    margin-bottom: 2em;
}

#diagramTabContents .diagram-selector-title {
    font-weight: bolder;
    margin-bottom: 1em;
}

#diagramTabContents a.diagram-selector-diagram {
    width: 150px;
    border: 4px solid transparent;
    padding: 0 12px;
    word-wrap: break-word;
}

#diagramTabContents img.diagram-selector-image {
    height: 120px;
    width: 100%;
    border: 1px solid #d9d9d9;
    margin-bottom: 12px;
}
#diagramTabContents img.diagram-selector-image.selected {
    border: 4px solid #ff671b;
}

#diagramTabContents .swiper-button-prev, #diagramTabContents .swiper-button-next {
    height: 40px;
    margin-top: -40px;
    background-color: #ff671b;
    color: #fff;
    width: 20px;
}

#diagramTabContents .swiper-button-prev svg, #diagramTabContents .swiper-button-next svg {
    margin-top: 10px;
    height: 13px;
    width: 13px;
}

#diagramTabContents .swiper-button-prev {
    left: 0;
    background-image: none;
    clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
    padding: 3px 4px 2px 2px;
}

#diagramTabContents .swiper-button-next {
    right: 0;
    background-image: none;
    clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0% 100%);
    padding: 3px 2px 2px 4px;
}

#diagramTabContents .swiper-button-disabled {
    visibility: hidden;
}
