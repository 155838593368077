.slider {
    background: #FF671B;
    position: relative;
    padding: 0 0 112px 0;
}

.slider__mobile-title {
    padding: 20px 20px 0 20px;
    margin: 0;
    font-size: 15px;
}

.slider__search-box {
    display: none;
    background: #FF671B;
    position: relative;
    z-index: 2;
}

.slider__search-box .search-box {
    padding: 20px;
}

.slider__search-box--visible {
    display: block;
    height: 260px;
}

.slider__toggle-search-box {
    display: block;
    color: #000;
    padding: 12px 54px;
    font-weight: bold;
    background-color: #FFBD00;
    position: relative;
    font-size: 14px;
}

.slider__toggle-search-alert-icon {
    position: absolute;
    left: 20px;
    top: 15px;
    width: 16px;
    height: 16px;
}

.slider__toggle-search-close {
    position: absolute;
    right: 20px;
    top: 15px;
    width: 16px;
    height: 16px;
}

.slider__toggle-search-close svg {
    width: 16px;
    height: 16px;
}

.slider__toggle-search,
.slider__toggle-search:hover{
    color: #000;
    font-weight: normal;
    text-decoration: underline;
    display: flex;
    align-items: center;
}

.slider__toggle-search-icon {
    display: flex;
    align-items: center;
    margin-left: 4px;
}

.slider__toggle-search-icon svg {
    height: 14px;
    width: 14px;
}

.slider__shop-selection {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    border-bottom: 5px solid #FF671B;
}

.slider__slides {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    list-style: none;
}

.slider__slide {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.slider__slide:first-child {
    display: block;
}

.slider__slide-image {
    height: 100%;
    background-size: cover;
}

.slider__slide-button {
    display: inline-block;
    color: #fff;
    background: #FF671B;
    padding: 5px 15px 5px 10px;
    white-space: nowrap;
    font-weight: bold;
}

.slider__slide-button:hover {
    background: #fff;
    color: #FF671B;
    text-decoration: none;
}

.slider__slide-text {
    position: absolute;
    left: 540px;
    top: 30px;
    color: #fff;
    font-family: 'PT Sans', sans-serif;
}

.slider__slide--alignment-onder .slider__slide-text {
    top: auto;
    bottom: 30px;
}

.slider__slide-title {
    font-size: 30px;
    margin: 0;
    text-transform: uppercase;
}

.slider__slide-description {
    margin: 0 0 10px 0;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 20px;
}


/* Tablet */
@media (min-width: 768px) {
    .slider {
        padding: 0;
    }
    .slider__shop-selection {
        position: inherit;
    }
}

/* Desktop small */
@media (min-width: 1024px) {
    .slider {
        background-color: #000;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 0 0 112px 0;
    }

    .slider__shop-selection {
        position: absolute;
    }

    .slider--medium {
        height: 314px;
    }

    .slider--medium-large {
        height: 360px;
    }

    .slider--medium .slider__search-box {
        padding-bottom: 20px;
    }

    .slider--medium .search-box__section:nth-child(1) {
        display: block;
    }

    .slider--large {
        height: 370px;
    }

    .slider__search-box .search-box {
        padding: 0;
    }

    .slider__search-box {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: #FF671B;
        width: 452px;
        padding: 20px;
        z-index: 1;
    }

    .slider__search-box:after {
        content: "";
        position: absolute;
        top: 0;
        left: 100px;
        right: -32px;
        height: 100%;
        background: #FF671B;
        z-index: -1;
        transform: skew(-10deg);
    }

    .slider__toggle-search-box {
        display: none;
    }
}

/* Desktop medium */
@media (min-width: 1170px) {

}

/* Desktop large */
@media (min-width: 1280px) {
    .slider__search-box {
        top: -32px;
        width: 452px;
        padding: 30px 10px 30px 50px;
    }
}
