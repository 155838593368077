.rma__header {
    background: #F3F3F3;
    padding: 0 20px;
    margin-bottom: 20px;
}

.rma__header-content {
    padding: 30px 0;
}

.rma__header-content p {
    color: #9d9d9d;
    line-height: 24px;
}

.rma__header-image img {
    display: none;
}

.rma__title {
    font-size: 22px;
    font-weight: bold;
}

.rma_form_container {
    padding: 40px;
}

@media (min-width: 768px) {
    .rma__header {
        padding: 0 50px;
    }

}

@media (min-width: 1024px) {
    .rma__header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .rma__header-content {
        flex-basis: 45%;
    }

    .rma__header-image img {
        flex-basis: 45%;
        transform: translateY(40px);
        padding: 0;
        display: block;
    }
}