.searchselection {}

.searchselection-header {
    font-size: 16px;
}

.searchselection-header .btn {
    margin: 10px 10px 0 0;
}

.searchselection-details-car {
    padding-top: 12px;
    padding-bottom: 12px;
    line-height: 2em;
    margin: 0;
    color: #000;
}

.searchselection-details-car-image {
    padding: 0 5px 0 0;
}

.searchselection-details-car-image img {
    max-width: 100%;
}

.searchselection-details-image img {
    max-width: 100%;
}

.searchselection-buttons {
    margin: 0;
}

.searchselection-buttons__main {
    max-width: 90%;
    display: flex;
}

.searchselection .modelselection {
    width: 400px;
}

.searchselection .notification {
    padding: 15px;
}

.searchselection__mobile {
    display: none;
}

@media (max-width: 1024px) {
    .searchselection-in-slider {
        padding: 15px 30px;
        position: relative;
    }
}

@media (max-width: 767px) {
    .searchselection__mobile {
        display: block;
    }

    .searchselection__desktop {
        display: none;
    }

    .searchselection-details-car {
        padding: 0;
        font-size: 14px;
    }

    .searchselection-details-car,
    .searchselection-details-extra {
        line-height: 1.5em;
    }

    .searchselection-in-slider .searchselection-header {
        text-align: left;
        color: #fff;
        font-size: 14px;
    }

    .searchselection-buttons__main {
        padding-top: 8px;
    }

    .searchselection-buttons__main .btn {
        font-size: 12px;
        margin: 0 15px 0 5px;
        padding: 4px 8px;
    }
}
