.breadcrumbs {
    padding: 12px 20px;
    font-size: 12px;
    color: #7b7b7b;
    background: #F3F3F3;
}

.breadcrumbs__item {
    display: inline-block;
}

.breadcrumbs__item:not(:last-child):after {
    content: ">";
    display: inline-block;
    margin: 0 5px;
}

.breadcrumbs__link {
    color: #7b7b7b;
}

@media (max-width: 768px) {
    .breadcrumbs__item:not(:last-child):not(:first-child):not(:nth-last-child(2)):not(:nth-child(2)) {
        display: none;
    }

    .breadcrumbs__item:nth-child(2):not(:last-child):not(:nth-last-child(2)) .breadcrumbs__link {
        display: none;
    }

    .breadcrumbs__item:nth-child(2):not(:last-child):not(:nth-last-child(2)):before {
        content: '..';
    }

    .breadcrumbs__item:not(:last-child) .breadcrumbs__link {
        font-weight: bold;
        color: #FF671B;
    }
}


@media (min-width: 768px) {
    .breadcrumbs {
        padding: 20px 30px;
        margin: 0;
    }

    .breadcrumbs--top-border {
        border-top: 1px solid #dbdbdb;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}